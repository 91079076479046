<template>
    <div v-if="check" ref="container" id="detail-content" class="content scrollbar right" :class="$mq">
        <div class="top-container" :class="{ infoOpened: infoIsOpen }">
            <AppccTopDetail :hasMoreOptions="true" @deleteButton="deleteIssue()" @infoOpened="infoOpened" @backButton="back()" @settingsButton="settingsRegister" :userInfo="check.employee" :title="cTitle != '' ? cTitle : check.title" :issue="check" :hasDelete="true" :hasReopen="false" />
        </div>
        <div class="template-sections" v-if="sections.length > 1">
            <Template-sections :sections="sections"></Template-sections>
        </div>
        <div class="resume">
            <div class="change-state"></div>
            <!-- <div class="select" v-if="Object.values(issueStates).length > 0">
                <div class="ball" :style="{ backgroundColor: issueStates[check.status].color }"></div>
                <select v-if="issueStates" class="state-selected" :class="'status-' + check.status" @change="changeState" @click="disableSelector">
                    <option v-for="(state, index) in sortedIssueStates" :key="index" :value="state.id" :selected="check.status == state.id">
                        <div class="state-name">{{ state.name }}</div>
                    </option>
                </select>
            </div> -->
            <div class="state-container" ref="stateSelector" :class="{ isOpen: isSelectorOpen }" @click="isSelectorOpen = !isSelectorOpen">
                <div class="current-state" v-if="Object.values(issueStates).length > 0" :class="{ isOpen: isSelectorOpen }">
                    <div class="ball" v-if="issueStates[check.status].color" :style="{ backgroundColor: issueStates[check.status].color }"></div>
                    <div class="name">
                        {{ issueStates[check.status].name }}
                    </div>
                </div>
                <div class="state-selector" v-if="isSelectorOpen" :class="{ active: isSelectorOpen }">
                    <div class="state" v-for="(state, index) in sortedIssueStates" @click="changeState(state.id)" :key="index" :class="{ selected: check.status == state.id }">
                        <div class="ball" :style="{ backgroundColor: state.color }"></div>
                        <div class="name">
                            {{ state.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="detail-box" :class="[{ 'with-sections': sections.length > 1 }, { 'opened-info': infoIsOpen }]">
            <div v-if="collapsedInfo" class="list-info" :class="{ 'with-sections': sections.length > 1 }">
                <span class="action delete" @click="deleteIssue()">{{ $t('issues.actions.delete') }}</span>
            </div>

            <div class="exam performing hide-scrollbar" id="exam" :class="[{ 'hide-scrollbar': ['portrait'].includes($mq) }, { scrollbar: ['landscape', 'desktop'].includes($mq) }]">
                <div v-if="check.description" class="check-description">
                    <div class="message keep-format" v-html="descriptionParse(check.description)" @click="view_more = !view_more"></div>
                </div>

                <div class="note-detail">
                    <NoteDetail v-if="notes && notes.length > 0" :notes="notes" />
                </div>

                <div v-if="check.recover && editable && !check.complete_date" class="get-last-values">
                    <span class="message" @click="recoverValues()">{{ $t('tasks.get_last_values') }}</span>
                </div>

                <template v-if="check.data && check.data.ref && loadRef">
                    <div class="message-container">
                        <Message :message="getStringVariables('answer.reference.task', refTask)" :buttonLabel="getString('answer.reference.task_action')" @buttonAction="goToReference" type="info"></Message>
                    </div>
                    <!-- <div v-else class="reference-link" @click="goToReference">
                        <div class="alex"></div>
                        <div class="message keep-format" @click="goToReference" v-html="getStringVariables('answer.reference.task', refTask)"></div>
                        <div class="read-more">
                            {{ getString('answer.reference.task_action') }}
                        </div>
                    </div> -->
                </template>
                <template v-if="sensorObject">
                    <div class="message-container">
                        <Message
                            :message="
                                getStringVariables('answer.issue_generated_by_sensor', {
                                    sensor: sensorObject ? sensorObject.name : '',
                                    value: check.data.ref.value,
                                    date: formatDate(check.data.ref.date)
                                })
                            "
                            :buttonLabel="getString('answer.reference.task_action')"
                            @buttonAction="goToSensor"
                            type="info"
                        ></Message>
                    </div>
                </template>

                <template v-if="check && Object.values(issueStates).length > 0">
                    <answer v-for="(answer, index) in check.items" :key="index" @acTitle="updateTitle" :isIssueClosed="issueStates[check.status].type == 3" :item="answer" :list="check" :cId="check.id" :aId="answer.item_id" @checkAllMandatory="checkAllMandatory" :cType="type" :showErrors="showErrors"></answer>
                    <div class="close-issue" v-if="allMandatory && Object.values(issueStates).length > 0">
                        <div class="text">
                            {{ $t('issue.want_close') }}
                        </div>
                        <div class="state-card" @click="changeState(getIssueStateClose().id)">
                            <div class="ball" :style="{ backgroundColor: getIssueStateClose().color }"></div>
                            <div class="state-name">{{ $t('issues.close_issue') }}</div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <div v-else class="content hide-scrollbar right empty">
        <!-- <div class="message empty">
            {{ getString('issues.empty') }}
        </div> -->
        <EmptyPage :buttonText="$t('empty_pages.return_issues')" @buttonAction="back()" buttonClass="back" :type="'error'" :title="$t('assets.overlay.timeout.message')" :description="$t('empty_pages.error_issues')"></EmptyPage>
    </div>
</template>

<script>
import EmptyPage from '../../layout/EmptyPage.vue'
import { FORMAT } from '@/constants'
import answer from '@/components/domain/tasks/answer'
import AppccTopDetail from '@/components/layout/AppccTopDetail'
import UserTag from '@/components/layout/UserTag'
import Message from '@/components/domain/ui/Message'
import TemplateSections from '@/components/layout/TemplateSections'
import NoteDetail from '@/components/layout/NoteDetail'

export default {
    components: { answer, UserTag, EmptyPage, AppccTopDetail, Message, TemplateSections, NoteDetail },
    props: {
        gId: { type: String },
        askTitle: { type: Boolean, default: false },
        permissions: { type: Boolean, default: false }
    },
    data() {
        return {
            isSelectorOpen: false,
            collapsedInfo: false,
            type: 4,
            refTask: {},
            FORMAT: FORMAT,
            loadRef: false,
            infoIsOpen: false,
            view_more: false,
            stateSelected: false,
            allMandatory: false,
            stateChanged: false,
            issueGeneratedBySensor: false,
            sensor: false,
            showErrors: false,
            showNonApply: true,
            cTitle: ''
        }
    },
    computed: {
        done() {
            return parseInt((this.check.completed / this.check.total) * 100)
        },
        check() {
            let result = this.$store.getters['issues/getById'](this.gId)
            if (!this.refCheck || this.refCheck == '') {
                this.loadReference(result)
            }
            if (result && result.data && result.data.ref && result.data.ref.sensor) {
                this.issueGeneratedBySensor = true
                this.sensor = result.data.ref.sensor
            }
            // Esconder botones en caso de que aparezca el error
            if (result == undefined) {
                this.$bar.hide()
            }
            return result
        },
        sensorObject() {
            if (this.check && this.check.data && this.check.data.ref && this.check.data.ref.sensor) return this.$store.getters['sensor/getList'](this.check.data.ref.sensor)
            else return false
        },
        sections() {
            var sections = []
            for (var i in this.check.items) {
                if (this.check.def.item.itemsTitle.indexOf(this.check.items[i].type) != -1) {
                    sections.push(this.check.items[i])
                }
            }
            return sections
        },
        // leftTime() {
        //     return this.$root.timeLeft(this.check.schedule.end)
        // },

        issueStates() {
            return this.$store.getters['getIssueStates']
        },
        sortedIssueStates() {
            let states = this.$store.getters['getIssueStates']
            let sortedStates = states ? Object.values(states).sort((states1, states2) => (states1.type > states2.type ? 1 : states1.name < states2.name ? -1 : 1)) : []
            return sortedStates
        },
        loggedUser() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        user() {
            if (this.loggedUser) {
                return this.$store.getters['employee/getEmployee'](this.loggedUser)
            }
            return false
        },
        itemsExcluded() {
            return this.$store.getters['tasks/getItemsExcluded']
        },
        roles() {
            return this.$store.getters['employee/getAllRoles']
        },
        roleUserLogged() {
            var user = this.$store.getters['loginUser/getLocalEmployee']
            var roleUserLogged = this.$store.getters['employee/getEmployee'](user)
            var levelRoleUserLogged = this.$store.getters['employee/getRol'](roleUserLogged.rol)
            return levelRoleUserLogged
        },
        notes() {
            return this.$store.getters['issues/getNotesById'](this.gId)
        }
    },
    methods: {
        closeBoxes(e) {
            if (!this.$refs['stateSelector'].contains(e.target)) {
                this.isSelectorOpen = false
            }

            const permissions = this.$store.getters['issues/getById'](this.$route.params.id)
            if (permissions.config.nRoles && !this.verifyPermissedDelete()) {
                if (this.rolesManagePermissions()) {
                    e.target.value = this.check.status
                    this.$popup.alert({
                        message: this.$t('answer.timing.not_has_role_permission', {
                            name: this.user.name,
                            roles: this.rolesManagePermissions()
                        })
                    })
                } else {
                    e.target.value = this.check.status
                    this.$popup.alert({
                        message: this.$t('home.permissions_messages.no_manage', {
                            name: this.user.name,
                            type: this.$t('home.types_register.issue')
                        })
                    })
                }

                this.isSelectorOpen = false
                return
            }

            if (!fnCheckConnection() && !this.$store.getters['getOffline']) {
                const self = this
                self.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('popup.issues.offline_update'),
                    textSave: self.$t('popup.audits.exit'),
                    callSave: function (response) {
                        self.$popup.close()
                    }
                })
                //disabled selector
                this.isSelectorOpen = false
                return
            }
        },
        updateTitle() {
            if (this.$store.getters['issues/getCustomTitle'](this.check.id).title) {
                return (this.cTitle = this.$store.getters['issues/getCustomTitle'](this.check.id))
            }
        },
        rolesWithWritePermissions() {
            const permissions = this.$store.getters['issues/getById'](this.$route.params.id)
            log('zzz log', permissions)
            const roles = []
            if (permissions && permissions.config && permissions.config.nRoles && permissions.config.nRoles.length > 0) {
                const idOfRoles = permissions.config.nRoles.map((role) => {
                    if (role.permissions.includes('w')) {
                        return role.roleId
                    }
                })
                Object.values(this.roles).map((role) => {
                    if (idOfRoles.includes(role.id)) {
                        roles.push(role.name)
                    }
                })
            }

            return roles
                .join(', ')
                .toString()
                .replace(/,(?=[^,]*$)/, ' ' + this.$t('answer.or'))
        },
        rolesManagePermissions() {
            const permissions = this.$store.getters['issues/getById'](this.$route.params.id)
            log('zzz log', permissions)
            const roles = []
            if (permissions && permissions.config && permissions.config.nRoles && permissions.config.nRoles.length > 0) {
                const idOfRoles = permissions.config.nRoles.map((role) => {
                    if (role.permissions.includes('rwx')) {
                        return role.roleId
                    }
                })
                Object.values(this.roles).map((role) => {
                    if (idOfRoles.includes(role.id)) {
                        roles.push(role.name)
                    }
                })
            }

            return roles
                .join(', ')
                .toString()
                .replace(/,(?=[^,]*$)/, ' ' + this.$t('answer.or'))
        },
        settingsRegister() {
            const self = this
            this.$popup.itemSettings({
                itemSettings: {
                    noApply: {
                        label: self.$t('popup.item_settings.no_apply'),
                        value: self.showNonApply
                    }
                },
                callSave: (settings) => {
                    const itemSettings = settings.itemSettings
                    self.showNonApply = itemSettings.noApply.value
                    self.$popup.close()
                }
            })
        },
        disableSelector(e) {
            const permissions = this.$store.getters['issues/getById'](this.$route.params.id)
            if (permissions.config.nRoles && !this.verifyPermissedDelete()) {
                if (this.rolesManagePermissions()) {
                    e.target.value = this.check.status
                    this.$popup.alert({
                        message: this.$t('answer.timing.not_has_role_permission', {
                            name: this.user.name,
                            roles: this.rolesManagePermissions()
                        })
                    })
                } else {
                    e.target.value = this.check.status
                    this.$popup.alert({
                        message: this.$t('home.permissions_messages.no_manage', {
                            name: this.user.name,
                            type: this.$t('home.types_register.issue')
                        })
                    })
                }

                e.target.disabled = true
                return
            }

            if (!fnCheckConnection() && !this.$store.getters['getOffline']) {
                const self = this
                self.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('popup.issues.offline_update'),
                    textSave: self.$t('popup.audits.exit'),
                    callSave: function (response) {
                        self.$popup.close()
                    }
                })
                //disabled selector
                e.target.disabled = true
                return
            }
        },
        infoOpened() {
            this.infoIsOpen = !this.infoIsOpen
        },
        getIssueStateClose() {
            // get first issueState with type 3
            return Object.values(this.issueStates).find((issueState) => issueState.type == 3)
        },
        back() {
            if (this.stateChanged) {
                this.$emit('changedState')
            }
            this.$router.push({ name: 'Issues' })
        },
        descriptionParse(msg) {
            if (msg.split(' ').length < 100) {
                return msgParse(msg)
            } else {
                if (this.view_more) {
                    return msgParse(msg) + ' <span class="more"> ' + this.$t('label.less') + '</span>'
                } else {
                    var maxLength = 150 // maximum number of characters to extract

                    var trimmedMsg = msg.substr(0, maxLength)

                    trimmedMsg = trimmedMsg.substr(0, Math.min(trimmedMsg.length, trimmedMsg.lastIndexOf(' ')))
                    return msgParse(trimmedMsg) + '... <span class="more">' + this.$t('label.more') + '</span>'
                }
            }
        },
        formatDate(date, format) {
            return this.$root.formatMoment(date, format)
        },
        getString(str) {
            return this.$t(str)
        },
        getStringVariables(str, params) {
            return this.$t(str, params)
        },
        getStringPlural(str, pos) {
            return this.$tc(str, pos)
        },
        getStringVariable(str, pos) {
            return this.$t(str, { s: pos })
        },
        getStringPluralVariable(str, pos, text) {
            return this.$tc(str, pos, { s: text })
        },

        recoverValues() {
            var self = this
            self.$overlay.loading()

            self.$store.dispatch('issues/recoverValues', { check: self.gId }).then(function (response) {
                self.$overlay.hide()
                self.$snackbar.info({ message: self.$t('tooltip.done') })
            })
        },

        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },

        format(value, type, item) {
            let response = value
            switch (parseInt(type)) {
                case FORMAT.CHECK:
                case FORMAT.YESNO:
                    response = value ? '✅' : '❌'
                    break
                case FORMAT.YESNONC:
                    if (value == 1) response = '✅'
                    else if (value == 2) response = '❌'
                    else response = this.i18n.t('answer.nsnc')
                    break
                case FORMAT.DATE:
                    response = value ? moment(value).format('DD/MM/YYYY') : ''
                    break
                case FORMAT.DATETIME:
                    response = value ? moment(value).format('DD/MM/YYYY HH:mm') : ''
                    break
                case FORMAT.PHOTO:
                case FORMAT.SIGNATURE:
                    response = value ? '<img src="' + value + '"/>' : value
                    break
                case FORMAT.TEMPERATURE:
                    const units = item.config.units
                    response = `${value}${units}`
                    break
                case FORMAT.EMAIL:
                    response = value ? this.i18n.t('answer.email.send') : this.i18n.t('answer.email.notSend')
                    break
            }
            return response
        },

        toggleInfo() {
            this.collapsedInfo = !this.collapsedInfo
        },

        deleteIssue() {
            if (!this.verifyPermissedDelete()) {
                if (this.rolesManagePermissions()) {
                    this.$popup.alert({
                        message: this.$t('answer.timing.not_has_role_permission', {
                            name: this.user.name,
                            roles: this.rolesManagePermissions()
                        })
                    })
                } else {
                    this.$popup.alert({
                        message: this.$t('home.permissions_messages.no_delete', {
                            name: this.user.name,
                            type: this.$t('home.types_register.issue')
                        })
                    })
                }
                return
            }

            if (!fnCheckConnection()) {
                this.$popup.alert({
                    message: this.$t('popup.issues.offline_remove')
                })
                return
            }

            var self = this
            var employee = self.currentEmployee()

            if (!self.$store.getters['hasAccess'](employee.id, 1)) {
                self.$popup.access({
                    list: self.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function (response) {
                        if (response.status) {
                            self.$popup.close()
                            self.popupDeleteIssue(response.emp_id)
                        }
                    }
                })
            } else {
                self.popupDeleteIssue(employee.id)
            }
        },
        verifyPermissedDelete() {
            if (this.check.config && !this.check.config.nRoles) {
                return true
            }

            if (this.check && this.check.config && Array.isArray(this.check.config.nRoles) && this.check.config.nRoles.length > 0) {
                let permissions = ''
                this.check.config.nRoles.forEach((employee) => {
                    if (employee.roleId === this.user.rol) {
                        permissions += employee.permissions
                    }
                })
                return permissions.includes('rwx')
            }
            return false
        },
        popupDeleteIssue(emp_id) {
            var self = this
            var relItem = self.refTask ? self.refTask.task_id : false

            self.$popup.delete({
                message: self.$t('issues.delete_msg', { issue: self.check.title }),
                textSave: self.$t('popup.delete.text_save'),
                textCancel: self.$t('popup.delete.text_cancel'),
                callSave: function () {
                    self.$overlay.loading()
                    self.$store.dispatch('issues/delete', { check: self.gId, emp: emp_id, ref_item: relItem }).then(function (response) {
                        if (response) {
                            self.$popup.close()
                            self.$snackbar.success({ duration: 4000, closeable: true, message: self.$t('issues.deleted_msg') })

                            self.$store.dispatch('tasks/updateIssue', { check: self.refTask.check_id, answer: self.refTask.task_id, toDelete: true })

                            self.$router.push({ name: 'Issues', params: {} })
                        }
                        self.$overlay.hide()
                    })
                }
            })
        },
        checkAllMandatory(mounted) {
            // check if current status of check is type 3
            if (this.check && Object.values(this.issueStates).length > 0 && this.issueStates[this.check.status].type == 3) {
                return
            }
            if (this.check && this.check.items) {
                // check item.type is not is itemsExcluded array
                let mandatoryItems = Object.values(this.check.items).filter((item) => item.mandatory && item.is_visible && !this.itemsExcluded.find((it) => it == item.type))
                let completedItems = mandatoryItems.filter((item) => item.complete_date)
                if (mandatoryItems.length == completedItems.length) {
                    var self = this
                    this.allMandatory = true
                    if (!mounted) {
                        self.$popup.confirm({
                            // TODO usuario logueado
                            message: self.$t('issues.popup.mark_as_completed', { name: self.user.name }),
                            textSave: self.$t('issues.popup.confirm'),
                            textCancel: self.$t('issues.popup.cancel'),
                            callSave: () => {
                                // get issueState id with type 3
                                let issueState = Object.values(self.issueStates).filter((state) => state.type == 3)
                                self.$store.dispatch('issues/update', { check: self.gId, status: issueState[0].id })
                                self.$store.dispatch('tasks/updateIssue', {
                                    check: self.refTask.check_id,
                                    answer: self.refTask.task_id,
                                    status: issueState[0].id
                                })
                                self.allMandatory = false
                            }
                        })
                    }
                } else {
                    this.allMandatory = false
                }
            }
        },

        changeState(e) {
            const permissions = this.$store.getters['issues/getById'](this.$route.params.id)
            if (permissions.config.nRoles && !this.verifyPermissedDelete()) {
                if (e.target) {
                    e.target.value = this.check.status
                }

                if (this.rolesManagePermissions()) {
                    this.$popup.alert({
                        message: this.$t('answer.timing.not_has_role_permission', {
                            name: this.user.name,
                            roles: this.rolesManagePermissions()
                        })
                    })
                } else {
                    this.$popup.alert({
                        message: this.$t('home.permissions_messages.no_manage', {
                            name: this.user.name,
                            type: this.$t('home.types_register.issue')
                        })
                    })
                }
                return
            }

            if (!fnCheckConnection() && !this.$store.getters['getOffline']) {
                const self = this
                self.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('popup.issues.offline_update'),
                    textSave: self.$t('popup.audits.exit'),
                    callSave: function (response) {
                        self.$popup.close()
                    }
                })
                return
            }

            if (typeof this.check !== 'undefined') {
                // check if new state is to close issue
                let val = ''
                if (typeof e == 'string') {
                    val = e
                } else {
                    val = e.target.value
                }
                if (this.issueStates[val].type == 3) {
                    // check if all items mandatory are filled
                    var items = Object.values(this.check.items)
                    var mandatory = true
                    for (var i = 0; i < items.length; i++) {
                        if (items[i].mandatory && items[i].is_visible && (items[i].value === '' || items[i].value == null) && items[i].type != 8) {
                            mandatory = false
                            let element = document.getElementById(items[i].item_id)

                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth', block: 'start' })
                            }
                            break
                        }
                    }
                    if (!mandatory) {
                        this.$popup.alert({
                            message: this.$t('issues.mandatory_msg')
                        })
                        this.showErrors = true
                        if (typeof e != 'string') {
                            e.target.value = this.check.status
                        }
                        val = this.check.status
                        return
                    }
                    this.$store.dispatch('issues/update', { check: this.gId, status: val })
                    this.$store.dispatch('tasks/updateIssue', { check: this.refTask.check_id, answer: this.refTask.task_id, status: val })
                    this.allMandatory = false
                    return
                } else {
                    var self = this
                    this.$store.dispatch('issues/update', { check: this.gId, status: val }).then(() => {
                        self.$store.dispatch('tasks/updateIssue', { check: self.refTask.check_id, answer: self.refTask.task_id, status: val }).then(() => {
                            self.checkAllMandatory(true)
                        })
                    })
                }
                this.stateChanged = true
            }
        },
        toSection(id) {
            const section = document.getElementById(id)

            switch (this.$mq) {
                case 'desktop':
                case 'landscape':
                    document.getElementById('detail-content').scrollTo({
                        top: section.offsetTop - 150,
                        behavior: 'smooth'
                    })
                    break
                case 'portrait':
                    document.getElementById('content').scrollTo({
                        top: section.offsetTop - 150,
                        behavior: 'smooth'
                    })
                    break
            }
        },
        loadSensors() {
            var self = this
            self.$store.dispatch('sensor/loadList').then(function () {})
        },
        loadReference(checklist) {
            log(checklist)
            if (checklist != undefined && checklist && checklist.data && checklist.data.ref && checklist.data.ref.type) {
                let self = this
                let refCheck = checklist.data.ref.checklist
                let refTask = checklist.data.ref.item
                log(checklist.data.ref)
                log(this.referenceType(checklist.data.ref.type))

                self.$store
                    .dispatch(this.referenceType(checklist.data.ref.type) + '/loadById', {
                        id: refCheck
                    })
                    .then(function () {
                        var response = self.$store.getters[self.referenceType(checklist.data.ref.type) + '/getById'](refCheck)
                        if (response) {
                            self.loadRef = true
                            var section = false
                            var task_title = ''
                            var task_id = ''
                            var task_value = ''
                            var task_description = ''
                            var task_section = ''
                            if (parseInt(checklist.data.ref.type) == 2) {
                                for (var k in response.sections) {
                                    var sec = response.sections[k]
                                    section = typeof sec.items[refTask] !== 'undefined' ? k : false
                                }

                                if (section) {
                                    task_title = response.sections[section].items[refTask].title
                                    task_id = response.sections[section].items[refTask].item_id
                                    task_value = self.format(response.sections[section].items[refTask].value, response.sections[section].items[refTask].type, false)
                                    task_description = response.sections[section].items[refTask].description
                                    task_section = response.sections[section].name
                                }
                            } else {
                                const item = response.items[refTask]
                                task_title = response.items[refTask].title
                                task_id = response.items[refTask].item_id
                                task_value = self.format(response.items[refTask].value, response.items[refTask].type, item)
                                task_description = response.items[refTask].description

                                const items = Object.values(response.items).sort(function (firstItem, nextItem) {
                                    return firstItem.order - nextItem.order
                                })

                                let indexOfItem = ''
                                items.find((item, index, _) => {
                                    if (item.item_id === response.items[refTask].item_id) {
                                        indexOfItem = index
                                    }
                                })

                                if (indexOfItem != '') {
                                    const allItemsDivided = items.slice(0, indexOfItem)
                                    //task_section = allItemsDivided[allItemsDivided.length - 1].title
                                    task_section = allItemsDivided.reverse().find((item) => item.type == 8)
                                    task_section = task_section ? task_section.title : ''
                                }
                            }
                            self.refTask = {
                                check_id: response.id,
                                check_title: response.title,
                                check_business_date: response.business_date,
                                check_date: moment(response.business_date).format('dddd DD MMM YYYY'),
                                task_title: task_title,
                                task_id: task_id,
                                task_value: task_value,
                                task_description: task_description ? task_description : '-',
                                task_section: task_section ? task_section : '-'
                            }
                        }
                    })
            } else {
                this.loadRef = false
            }
        },
        goToReference() {
            if (this.check && this.check.data && this.check.data.ref) {
                var type = this.referenceType(this.check.data.ref.type)
                var info = this.$store.getters[type + '/getById'](this.check.data.ref.checklist)
                log(type, info)

                if (type == 'tasks') {
                    this.$router.push({
                        name: 'ChecklistDetail',
                        params: {
                            id: this.check.data.ref.checklist,
                            checkType: 1,
                            toTaskId: this.check.data.ref.item,
                            propDate: moment(this.refTask.check_business_date)
                        }
                    })
                } else if (type == 'audit') {
                    this.$router.push({
                        name: 'AuditEdit',
                        params: {
                            id: info.template_id,
                            checkType: 2,
                            toTaskId: this.check.data.ref.item,
                            propDate: moment(this.refTask.check_business_date),
                            edit: this.check.data.ref.checklist
                        }
                    })
                } else if (type == 'register') {
                    this.$router.push({
                        name: 'RegisterEdit',
                        params: {
                            id: info.template_id,
                            checkType: 3,
                            toTaskId: this.check.data.ref.item,
                            propDate: moment(this.refTask.check_business_date),
                            edit: this.check.data.ref.checklist
                        }
                    })
                }
            }
        },
        goToSensor() {
            if (this.check && this.check.data && this.check.data.ref && this.check.data.ref.sensor) {
                this.$router.push({
                    name: 'SensorDetail',
                    params: {
                        id: this.check.data.ref.sensor
                    }
                })
            }
        },
        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        },

        referenceType(type) {
            switch (parseInt(type)) {
                case 1:
                    return 'tasks'
                case 2:
                    return 'audit'
                case 3:
                    return 'register'
            }
        }
    },
    filters: {
        moment: function (date, format) {
            return date ? moment(date).format(format) : ''
        }
    },

    mounted() {
        let self = this
        if (this.check && Object.values(this.issueStates).length > 0) {
            this.checkAllMandatory(true)
        }
        self.$bar.reset()
        self.$bar.hide()
    },
    created() {
        if (localStorage.getItem('showNonApply') != undefined) {
            this.showNonApply = localStorage.getItem('showNonApply') == 'true' ? true : false
        }
    },
    updated() {
        // START LISTENING CLICKS WHEN UBICATION DIV IS OPENED
        if (this.isSelectorOpen) {
            document.addEventListener('click', this.closeBoxes)
        }
        // STOP LISTENING CLICKS WHEN UBICATION DIV IS CLOSED
        else {
            document.removeEventListener('click', this.closeBoxes)
        }
    },
    watch: {
        sensor: function (val) {
            if (val) {
                this.loadSensors()
            }
        },
        showNonApply(newValue, oldValue) {
            if (newValue !== oldValue) {
                localStorage.setItem('showNonApply', newValue)
                this.$store.commit('checklist/setShowNoApply', newValue)
            }
        }
        // check(val, old) {
        //     console.log(val, old)
        // }
        // updateTitle(val, old) {
        //     if(val) {
        //         console.log(val)
        //         this.cTitle = val
        //     }
        // }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.issues.detail {
    $column-width: 20%;
    $column-max-width: rem(60px);
    $content-width: 100%;

    > .content {
        $top-info: rem(90px);
        $resume-width: rem(150px);
        $detail-text-color: $main-dark;
        $top-info: rem(70px);
        $resume-width: rem(100px);
        overflow: hidden !important;
        padding-top: 0;
        padding-bottom: 0 !important;
        width: calc(#{$content-width}) !important;

        .detail-box {
            $sidebar-size: rem(40px);
            $collapse-button: rem(40px);
            $content-padding: rem(0px);
            $detail-bg: $default-bg-color;

            position: relative;
            padding: 0;
            padding-left: $content-padding;
            padding-bottom: $menu-aux-height;
            width: 100%;
            background-color: $detail-bg;
            height: calc(100% - 120px);
            overflow: auto;

            &.with-sections {
                // padding-top: $top-info + $sidebar-size;
            }

            .list-info {
                $action-size: rem(60px);
                @include default-box-shadow();
                @include display-flex();
                @include justify-content(flex-end);
                @include align-items();
                @include flex-direction(row);
                @include flex-wrap(nowrap);
                background-color: shade($default-bg-color, 5%);
                position: fixed;
                z-index: 9;
                top: $alex-app-padding + $menu-top-height + rem(11px) + $top-info;
                width: calc(100% - #{$column-max-width} - #{$menu-tools-min} - #{$content-padding} - #{$scroll-width});
                height: $action-size;

                &.with-sections {
                    top: $alex-app-padding + $menu-top-height + rem(11px) + $top-info + rem(40px);
                }

                .action {
                    @include font-size(xs);
                    font-family: $text-bold;
                    color: #444;
                    text-align: center;
                    display: block;
                    background-color: shade($default-bg-color, 7%);
                    width: $action-size;
                    height: $action-size;
                    padding: ($action-size - rem(20px)) 6px 2px 6px;
                    margin: 0 9px;
                    cursor: pointer;

                    &.delete {
                        @include background($image: img('delete_alert.svg'), $size: 24px, $position: top 9px center);
                    }
                }
            }

            .exam {
                @include border-radius($default-border-radius);
                // background-color: #fff;
                padding: 12px 0;
                height: 100%;

                .note-detail {
                    margin-top: 20px;
                    width: 100%;
                    padding: 0 0 0 0;
                    position: relative;
                }

                &:not(.performing) {
                    // border: 2px solid #eee;
                    // background-color: #eee;
                    // filter: grayscale(70%);
                }
                &.performing {
                }

                & > .reference-link {
                    @include display-flex();
                    @include align-items();
                    @include background($color: shade($default-bg-color, 4%), $image: img('alert_primary.svg'), $position: left 20px center, $size: rem(35px));
                    width: 100%;
                    padding: 16px 20px 16px rem(80px);
                    margin: 0 auto 0 auto;

                    .message {
                        @include border-radius(3px);
                        @include font-size(s);
                        color: $main-s40;
                        display: inline-block;
                        padding-right: 12px;
                        overflow: visible;
                        width: calc(100% - #{rem(100px)});
                        max-width: calc(100% - #{rem(40px)} - #{rem(6px)});
                        min-height: rem(40px);
                        height: auto;

                        > span {
                            @include keepFormat();
                            font-family: $text;
                        }
                        > .date {
                            @include font-size(s);
                            text-transform: capitalize;
                        }
                    }

                    .read-more {
                        @include border-radius(3px);
                        @include background($color: $main, $image: img('right_arrow_light.svg'), $position: right 6px center, $size: 20px);
                        @include font-size(s);
                        color: #fff;
                        font-family: $text;
                        padding: 9px 30px 9px 9px;
                        width: rem(100px);
                        display: inline-block;
                        background-color: $main;
                    }
                }

                .get-last-values {
                    text-align: center;

                    .message {
                        @include interaction();
                        @include border-radius($default-border-radius);
                        @include background($color: $main-t80, $image: img('download_primary.svg'), $position: right 12px center, $size: 22px);
                        @include font-size(m);
                        font-family: $text;
                        color: $main-dark;
                        padding: 12px 42px 12px 12px;
                        display: inline-block;

                        @include bgHover($main-t80);
                    }
                }

                .message-container {
                    width: 100%;
                    padding-left: 8px;
                }
                .close-issue {
                    @include display-flex();
                    @include align-items();
                    @include justify-content();
                    @include flex-direction(column);
                    padding-top: 30px;

                    .text {
                        @include font-size(m);
                        text-align: center;
                        font-family: $text;
                        color: $inactive-s60;
                        padding-bottom: 20px;
                    }
                    .state-card {
                        @include display-flex();
                        @include align-items();
                        @include border-radius(4px);
                        background-color: #fff;
                        padding: 13px;
                        margin-bottom: 20px;
                        cursor: pointer;

                        .ball {
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            margin-right: 6px;
                        }
                        .state-name {
                            @include font-size(sm);
                            font-family: $text-bold;
                        }
                        &:hover {
                            @include bgHover(#fff);
                        }
                    }
                }
            }
        }
        .resume {
            @include display-inline-flex();
            @include align-items();
            @include justify-content(flex-end);
            float: right;
            width: 100%;
            height: 55px;
            z-index: 999;

            .change-state {
                @include background($image: img('recargar.svg'), $size: 20px, $position: center center);
                @include border-radius(4px);
                background-color: $default-sec-color;
                width: 40px;
                height: 40px;
                margin-right: 4px;
                position: relative;
                // left: 50px;
                cursor: pointer;
                &:hover {
                    @include bgHover($default-sec-color);
                }
            }
            .select {
                position: relative;
                height: 40px;
                // width: 200px;
                width: 280px;
                max-width: 80%;
                @include display-flex();
                @include justify-content(flex-end);
                background-color: #fff;
                border-radius: 4px 0 0 4px;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 1) 100%);
                .ball {
                    width: 15px;
                    height: 15px;
                    min-width: 15px;
                    min-height: 15px;
                    border-radius: 50%;
                    margin-right: 8px;
                    // position: absolute;
                    // left: 58px;
                    // top: 13px;
                    z-index: 2;
                    margin: 10px -23px 0 0;
                }
                .state-selected {
                    @include display-flex();
                    @include align-items(center);
                    @include border-radius(4px);
                    @include font-size(m);
                    background-color: #fff !important;
                    height: 40px;
                    cursor: pointer;
                    // A reset of styles, including removing the default dropdown arrow
                    appearance: none;
                    // Additional resets for further consistency
                    border: none;
                    color: $inactive-s80;
                    font-family: $text-bold;
                    padding: unset;
                    margin: unset;
                    padding-right: 6px;
                    padding-left: 30px;
                    // max-width: 280px;
                    white-space: nowrap;
                    background: linear-gradient(90deg, rgba(199, 39, 123, 0) 25%, rgba(255, 255, 255, 1) 25%);
                    @include text-ellipsis();
                    text-align: left;
                    z-index: 999;

                    .state-name {
                        color: $inactive-s80;
                        font-family: $text-bold;
                    }

                    &::before {
                        content: ' ';
                        position: absolute;
                        left: -56px;
                        top: -6px;
                        height: 20px;
                        @include background($image: img('recargar.svg'), $size: 20px, $position: center center);
                        @include border-radius(4px);
                        background-color: $default-sec-color;
                        width: 40px;
                        height: 40px;
                        margin-right: 4px;
                        color: transparent;
                        cursor: pointer;
                        &:hover {
                            @include bgHover($default-sec-color);
                        }
                    }
                }
            }
            .state-container {
                position: relative;
                height: 40px;
                border-radius: 4px 0 0 4px;
                max-width: calc(100% - 40px);

                .current-state {
                    @include display-flex();
                    @include align-items(center);
                    @include border-radius(4px);
                    @include background($image: img('down_caret_dark.svg'), $position: right 6px center, $size: 6px);
                    border: 0.5px solid;
                    padding: 6px;
                    cursor: pointer;
                    max-width: 238px;
                    border: 1px solid $color-neutral-300;
                    height: 40px;
                    background-color: $color-white;
                    .ball {
                        width: 10px;
                        height: 10px;
                        min-width: 10px;
                        min-height: 10px;
                        border-radius: 50%;
                        margin-right: 8px;
                    }
                    .name {
                        @include text-ellipsis();
                        color: $inactive-s80;
                        font-family: $text-bold;
                        padding-right: 10px;
                    }
                    &.isOpen {
                        @include background($image: img('up_caret_dark.svg'), $position: right 6px center, $size: 6px);
                    }
                }

                .state-selector {
                    @include border-radius(3px);
                    top: 5px;
                    position: relative;
                    background-color: #fff;
                    width: 100%;
                    max-width: 238px;
                    box-shadow: 0px 0px 10px 0px rgba(117, 115, 119, 0.3);
                    z-index: 999;
                    padding-bottom: 6px;

                    .state {
                        width: 100%;
                        @include display-flex();
                        @include align-items(center);
                        @include border-radius(2px);
                        padding: 6px;
                        margin-right: 8px;
                        cursor: pointer;
                        .ball {
                            width: 10px;
                            height: 10px;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 50%;
                            margin-right: 8px;
                            margin-left: 2px;
                        }
                        .name {
                            // @include font-size(xs);
                            @include text-ellipsis();
                            color: $color-black;
                            max-width: 87%;
                            font-family: $text-bold;
                        }
                        &:hover {
                            @include bgHover(#fff);
                        }
                        &.selected {
                            background-color: #f4f4f4;
                            // @include background($image: img('tick_dark.svg'), $position: right 12px center, $size: 12px);
                            &:hover {
                                background: #f4f4f4;
                                // @include background($image: img('tick_dark.svg'), $position: right 12px center, $size: 12px);
                            }
                        }
                    }
                }
            }

            .toggle-info {
                @include background($image: img('more_vert_aaaaaa.svg'), $size: 22px);
                display: inline-block;
                width: rem(40px);
                height: 100%;

                &.opened {
                    background-image: img('more_vert_primary.svg');
                }
            }
        }

        .check-description {
            @include background($color: shade($default-bg-color, 4%), $image: img('file_attach_aaaaaa.svg'), $position: left 12px center, $size: 35px);
            padding: 16px 20px 16px 56px;
            margin: 0 6px 0 12px;

            .alex {
                @include background($image: img('alexhead_04.svg'), $size: contain, $position: left bottom);
                width: rem(40px);
                height: rem(40px);
                display: inline-block;
                margin-right: 6px;
            }

            .message {
                @include font-size(sm);
                font-family: $text;
                color: $main-dark;
                display: inline-block;
                overflow: visible;
                width: auto;
                max-width: calc(100% - #{rem(40px)} - #{rem(6px)});
                min-height: rem(20px);
                height: auto;

                .more {
                    color: $main-t20;
                    cursor: pointer;
                }
            }
        }
        .top-container {
            background-color: $default-bg-color;
            width: 100%;
            // padding: 12px;
            padding-bottom: 0px;
            height: auto;
            padding-top: 0px;
        }
        &.desktop {
            .top-container {
                background-color: $default-bg-color;
                width: 100%;
                padding-bottom: 0px;
                max-height: 95px;
            }
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 50px);
                &.with-sections {
                    height: calc(100% - 155px);

                    &.opened-info {
                        height: calc(100% - 205px);
                    }
                }
                &.opened-info {
                    height: calc(100% - 100px);
                }
            }
        }
        &.landscape {
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 50px);
                &.with-sections {
                    height: calc(100% - 155px);

                    &.opened-info {
                        height: calc(100% - 205px);
                    }
                }
                &.opened-info {
                    height: calc(100% - 170px);
                }
            }
        }
    }

    > .column.left {
        $content-space: 6px;
        width: $column-width !important;
        max-width: $column-max-width !important;
    }
}
</style>

<style lang="scss">
#content.issues.detail.portrait {
    padding: 0px !important;
    > #detail-content {
        height: 100% !important;
        margin: 0px !important;

        .select {
            .state-selected {
                @include font-size(s);
            }
        }

        .detail-box {
            padding-bottom: 0px !important;
            background: transparent;
            height: calc(100% - 105px);
            overflow: hidden;

            .exam {
                .get-last-values {
                    .message {
                        @include font-size(xs);
                        line-height: normal;
                    }
                }
            }

            .top-info {
                width: 100%;
                top: 60px;
                left: 0;
                padding: 10px;
                background-color: $default-bg-color;
                height: auto;

                .content {
                    padding: 5px !important;
                    height: auto;
                    width: 100%;
                    max-width: 100%;

                    .item-name {
                        line-height: 1rem;
                        @include font-size(sm, $important: true);
                        height: 25px;
                    }
                    .item-user {
                        position: absolute;
                        .name {
                            @include font-size(xs, $important: true);
                        }
                    }
                    .item-time {
                        @include font-size(xs);
                        line-height: 0.75rem;
                        margin-top: -5px;
                    }
                }

                .sidebar-sections {
                    position: relative;
                    top: 0;
                    background: transparent;

                    .control-overflow {
                        justify-content: flex-start;
                        .item {
                            @include font-size(xs);
                        }
                    }
                }
            }

            .exam {
                height: 100%;
                padding-bottom: 0px;
                overflow: auto;
                position: relative;
                z-index: 1;

                .justify-description,
                .check-available {
                    padding: 8px 20px 8px 50px;
                    background-size: 25px;
                    background-position: left 10px center;

                    .message {
                        @include font-size(xs);
                        line-height: 1rem;
                        width: calc(100% - 100px);
                    }
                    .action {
                        width: 100px;
                        @include font-size(xs);
                        line-height: 0.75rem;
                    }
                }
                .check-available {
                    .message {
                        width: 100%;
                        max-width: 100%;
                    }
                }
                .check-description {
                    padding: 8px 20px 8px 34px;
                    background-size: 25px;
                    background-position: left 4px center;
                    margin: 0px !important;

                    .message {
                        @include font-size(xs);
                        line-height: 1rem;
                    }
                }
                .close-issue {
                    @include display-flex();
                    @include align-items();
                    @include justify-content();
                    @include flex-direction(column);
                    padding-top: 30px;

                    .text {
                        @include font-size(sm);
                        text-align: center;
                        font-family: $text;
                        color: $inactive-s60;
                        padding-bottom: 20px;
                    }
                    .state-card {
                        @include display-flex();
                        @include align-items();
                        @include border-radius(4px);
                        background-color: #fff;
                        padding: 13px;
                        margin-bottom: 20px;
                        cursor: pointer;

                        .ball {
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            margin-right: 6px;
                        }
                        .state-name {
                            @include font-size(sm);
                            font-family: $text-bold;
                        }
                    }
                }
            }
            &.with-sections {
                height: calc(100% - 145px);
                &.opened-info {
                    height: calc(100% - 195px);
                }
            }
            &.opened-info {
                height: calc(100% - 155px);
            }
        }
        .resume {
            @include display-inline-flex();
            @include align-items();
            @include justify-content(flex-end);
            float: right;
            width: 100%;
            height: 55px;
            z-index: 999;
            position: relative;
            padding-right: 0px;
            .count {
                @include font-size(sm);
                float: right;
            }

            .current-state,
            .state-selector {
                font-size: 16px;
            }
        }
        .top-container {
            padding: 0px;
        }
    }
}
</style>
